<template>
  <div>
    <el-form ref="paramsformref" :model="paramsform" label-width="190px">
      <el-form-item :label="$t('cs.sfkfzc')">
        <el-radio-group v-model="paramsform.isregister">
          <el-radio label="1">{{ $t('shi') }}</el-radio>
          <el-radio label="0">{{ $t('fou') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('cs.mbptsqsxf')" prop="sitename">
        <el-input v-model="paramsform.order_fee"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.tzje')">
        <el-input v-model="paramsform.betamount"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.zdxdje')">
        <el-input v-model="paramsform.minimum"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.zgxdje')">
        <el-input v-model="paramsform.maximum"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.sfkftx')">
        <el-radio-group v-model="paramsform.withdrawl_opend">
          <el-radio label="1">{{ $t('shi') }}</el-radio>
          <el-radio label="0">{{ $t('fou') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('cs.mbtxsxf')">
        <el-input style="width: 300px;" v-model="paramsform.withdrawl_fee"></el-input>
		<span>%, 例如1%，只填写1</span>
      </el-form-item>
      <el-form-item :label="$t('cs.dbzdtxje')">
        <el-input v-model="paramsform.withdrawl_min"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.dbzgtxje')">
        <el-input v-model="paramsform.withdrawl_max"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.drljzgtxje')">
        <el-input v-model="paramsform.today_withdrawl_max"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.mrzgtxcs')">
        <el-input v-model="paramsform.withdrawl_num"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.txsj')">
        <el-input v-model="paramsform.withdrawl_times"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.txgg')">
        <el-input v-model="paramsform.withdrawl_notice"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paramsform: {
        isregister: '1',
        order_fee: '',
        betamount: '',
        minimum: '',
        maximum: '',
        withdrawl_fee: '',
        withdrawl_min: '',
        withdrawl_max: '',
        today_withdrawl_max: '',
        withdrawl_num: '',
        withdrawl_opend: '',
        withdrawl_notice: '',
        withdrawl_times: ''
      }
    }
  },
  created() {
    this.getparams()
  },
  methods: {
    async getparams() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/config/paramsconfig')
      if (data) {
        if (data.code === 200) {
          this.paramsform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交
    async onSubmit() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.post(
        '/admin/config/paramsconfig',
        this.paramsform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 重置
    reset() {
      this.getparams()
    }
  }
}
</script>
